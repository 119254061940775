<template>
  <div class="container-fluid err-page-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :keywords="metadata.keyword"
      :image="metadata.image"
      :url="metadata.url"
      :head="metadata.head"
    />

    <div class="row mx-0 bg-block">
      <div class="col-12 col-md-9 err-page-component">
        <h2 class="err-page-title">
          {{ errorTitle }}
        </h2>
        <!-- <a
          :href="page"
          class="err-page-page"
        >
          {{ page }}
        </a> -->
        <div class="err-page-divider"/>
        <p class="err-page-txt">
          {{ errorText }}
        </p>
        <div class="mt-5 text-muted">
          <router-link
            to="/"
            class="text-info"
          >
            <span v-t="{path: 'ERROR.BACK_HOME'}"/>
          </router-link>
        </div>
      </div>
      <div class="col-12 col-md-3 d-flex">
        <span class="far fa-frown err-page-img"/>
      </div>
    </div>
  </div>
</template>

<script>
  import JsonLD from 'src/service/jsonld.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import { ScreenReadyMixin } from 'src/plugins/mixin';

  export default {
    name: 'ErrorPage',
    mixins: [ScreenReadyMixin],
    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
      text: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {
        errtxt: 'Page not found',
        errcode: '404',
        errtitle: 'Page not found',
        page: '',
        head: {},
        metadata: {},
      };
    },
    computed: {
      errorTitle() {
        return this.isCustomError ? this.title : `${this.errcode} : ${this.errtitle}`;
      },
      errorText() {
        return this.isCustomError ? this.text : this.errtxt
      },
      isCustomError() {
        return this.title !== '' || this.text !== '';
      },
    },
    mounted() {
      let err = this.$plError.getLastError();
      if(err && err !== '') {
        this.errtxt = err.text;
        this.errcode = err.code;
        this.page = err.page;
        console.log(err);
        this.errtitle = err.title;
      } else {
        this.errtxt = 'Page not found';
        this.errcode = '404';
        this.errtitle = 'Page not found';
        this.page = window.location.href;
      }

      if (!this.isCustomError) {
        this.fillHeader();
      }
      this.setScreenReady({
        itype: 'screen_error',
        link: '/screen/error/',
        id: 'error',
      });
    },
    methods: {
      handleError(error) {
      },

      fillHeader() {
        JsonLD.update();
        this.head.title = this.errtitle;
        this.head.description = this.errtxt;
        this.metadata = normaliseMetaTags(this.head);

        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: this.errcode },
        });
      },
    },
  };
</script>

<style lang="scss">
	@import './ErrorPage';
</style>
